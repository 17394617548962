<template>
    <div>
        <div style="position: absolute; clip: rect(0,0,0,0);" id="printQueue">
            <h1 style="font-weight: bold;" class="display-2">
                {{ detailPrint.queueNo }}
            </h1>
        </div>
        <div class="printTicket" id="divToPrint">
            <div
                style="text-align:center; margin: 0 6pt 0 6pt; font-family: sans-serif"
            >
                <div style="font-size: 14px;">
                    {{
                        `${
                            tenantPrinter !== undefined &&
                            tenantPrinter !== null
                                ? tenantPrinter
                                : "RS MANDAYA"
                        }`
                    }}
                </div>
                <br />
                <div style="font-size: 12px;">
                    {{ detailPrint.displayName1 }}
                </div>
                <div style="font-size: 12px;">
                    {{ detailPrint.displayName2 ?  detailPrint.displayName2  : '' }}
                </div>
                <div style="font-size: 12px;" v-if="detailPrint.schedule">Appointment: {{ detailPrint.schedule }}</div>
                <div style="font-size: 55px;font-weight: bold;">
                    {{ detailPrint.queueNo }}
                </div>
                <br />
                <div style="font-size: 12px;">{{ detailPrint.date }}</div>
                <div style="font-size: 12px;">
                    Mohon menunggu nomor Anda dipanggil.
                </div>
                <br />
                <div class="content" style="display: flex; margin: 10px;">
                    <div class="col img" v-if="detailPrint.content">
                        <vue-qrcode
                            :value="detailPrint.content"
                            :margin="qrProps.margin"
                            :width="qrProps.width"
                            :errorCorrectionLevel="qrProps.errorCorrectionLevel"
                        />
                    </div>
                    <div class="col txt py-3" style="margin-left : 5px;">
                        <p style="font-size: 8px; text-align: left;">
                            Scan QR ini dengan Caredokter App
                        </p>
                        <p style="font-size: 8px; text-align: left;">
                            Download Aplikasinya di Google Play Store
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- tikcet kecil -->
        <div class="small-ticket d-none" id="small-ticket">
            <div
                style="text-align:center; margin: 0 6pt 0 6pt; font-family: sans-serif"
            >
                <!-- <div style="font-size: 14px;">MANDAYA HOSPITAL KARAWANG</div>
                <br /> -->
                <div style="font-size: 12px;">
                    {{ detailPrint.displayName1 }}
                </div>
                <div style="font-size: 12px;">
                    {{ detailPrint.displayName2 ? detailPrint.displayName2 : '' }}
                </div>
                <div v-if="modePrint !== 'unit'" style="font-size: 12px;">{{ detailPrint.schedule }}</div>
                <div style="font-size: 55px;font-weight: bold;">
                    {{ detailPrint.queueNo }}
                </div>
                <br />
                <div style="font-size: 12px;">{{ detailPrint.date }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const ServerTimestampRepository = RepositoryFactory.get("serverTimestamp");
const QueueResource = RepositoryFactory.get("queueUser");
import { Printd } from "printd";
import qz from "qz-tray";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    data() {
        return {
            qrProps: {
                margin: 0,
                width: 100,
                errorCorrectionLevel: "M"
            },
            tenantPrinter: null,
            selectedTent: null
        };
    },
    computed: {
        ...mapState("CallerStore", {
            defaultPrinter: state => state.defaultPrinter
        })
    },
    methods: {
        ...mapMutations({
            setLoading: "CallerStore/SET_LOADING"
        }),
        async printQueue(payload) {
            let vx = this;
            
            const printDiv = document.getElementById("divToPrint");
            if (payload.printD) {
                const d = new Printd();
                await d.print(printDiv);
                vx.setLoading(false);
                // vx.payloadPrint.printSelected = null;
            } else {
                var config = qz.configs.create(vx.defaultPrinter);
                // The QR data
                var qr = vx.detailPrint.content;
                // The dot size of the QR code
                var dots = "\x04";
                // Some proprietary size calculation
                var qrLength = qr.length + 3;
                var size1 = String.fromCharCode(qrLength % 256);
                var size0 = String.fromCharCode(Math.floor(qrLength / 256));
                let tenantPrinter = localStorage.getItem("tenantPrinter");

                const el = document.getElementById("printQueue");

                const options = {
                    type: "dataURL",
                    scale: 1
                    // width: "150px",
                    // height: "50px",
                };
                this.output = await this.$html2canvas(el, options);

                var data = [
                    "\x1B" + "\x40", //init
                    "\x1B" + "\x61" + "\x31", // center align
                    "\x1B" + "\x45" + "\x0D", // bold on
                    "\x1B" + "\x21" + "\x30", // em mode on
                    `${
                        tenantPrinter !== undefined
                            ? tenantPrinter
                            : "RS MANDAYA"
                    }`,
                    "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // em mode off
                    "\x1B" + "\x45" + "\x0A", // bold off
                    "\x0A" + "\x0A",
                    `${vx.detailPrint.displayName1}`,
                    "\x0A",
                    `${vx.detailPrint.displayName2 ? vx.detailPrint.displayName2: ''}`,
                    "\x0A",
                    {
                        type: "raw",
                        format: "image",
                        flavor: "base64",
                        data: this.output,
                        options: {
                            language: "ESCPOS"
                            //size: {width: 2.25, height: 1.25}, units: 'in',
                        }
                    },
                    "\x0A",
                    `${vx.detailPrint.date} `,
                    "\x0A",
                    "Mohon menunggu nomor Anda dipanggil",
                    "\x0A" + "\x0A", //new lines
                    // <!-- BEGIN QR DATA -->
                    "\x1D" + "\x28" + "\x6B" + "\x04" + "\x00" + "\x31" + "\x41" + "\x32" + "\x00", // <Function 165> select the model (model 2 is widely supported)
                    "\x1D" + "\x28" + "\x6B" + "\x03" + "\x00" + "\x31" + "\x43" +
                        dots, // <Function 167> set the size of the module
                    "\x1D" + "\x28" + "\x6B" + "\x03" + "\x00" + "\x31" + "\x45" + "\x30", // <Function 169> select level of error correction (48,49,50,51) printer-dependent
                    "\x1D" + "\x28" + "\x6B" +
                        size1 +
                        size0 + "\x31" + "\x50" + "\x30" +
                        qr, // <Function 080> send your data (testing 123) to the image storage area in the printer
                    "\x1D" + "\x28" + "\x6B" + "\x03" + "\x00" + "\x31" + "\x51" + "\x30", // <Function 081> print the symbol data in the symbol storage area
                    "\x1D" + "\x28" + "\x6B" + "\x03" + "\x00" + "\x31" + "\x52" + "\x30", // <Function 082> Transmit the size information of the symbol data in the symbol storage area
                    // <!-- END QR DATA -->
                    "\x0A",
                    "\x1B" + "\x4D" + "\x31", // small text
                    "Scan QR ini dengan Aplikasi Care Dokter",
                    "\x0A",
                    "Download di Google Play Store & Apple App Store",
                    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A", // more line feeds and text to see if we messed up the QR Code syntax
                    "\x1D" + "\x56" + "\x01" //CUT
                ];
                try {
                    await qz.print(config, data).catch(function(e) {
                        console.error(e);
                    });
                } catch (err) {
                    console.log(err);
                }
                if (localStorage.copiesNumber) {
                    var data = [
                        {
                            type: "pixel",
                            format: "html",
                            flavor: "plain", // or 'plain' if the data is raw HTML
                            data: `${
                                document.getElementById("small-ticket")
                                    .outerHTML
                            }`
                        }
                    ];
                    let counter = 0;
                    let maxNumber = localStorage.copiesNumber < 5 ? localStorage.copiesNumber : 5
                    while (counter != maxNumber) {
                        await qz.print(config, data).catch(function(e) {
                            console.error(e);
                        });
                        counter++;
                    }
                }
                vx.setLoading(false);
            }
        },
        async printSelectedQueue(payload) {
            let vx = this;

            const printDiv = document.getElementById("divToPrint");
            if (payload.printD) {
                const d = new Printd();
                await d.print(printDiv);
                vx.setLoading(false);
                // vx.payloadPrint.printSelected = null;
            } else {
                var config = qz.configs.create(vx.defaultPrinter);
                var qr = vx.detailPrint.content;
                // The dot size of the QR code
                var dots = "\x04";
                // Some proprietary size calculation
                var qrLength = qr.length + 3;
                var size1 = String.fromCharCode(qrLength % 256);
                var size0 = String.fromCharCode(Math.floor(qrLength / 256));

                let tenantPrinter = localStorage.getItem("tenantPrinter");
                if (tenantPrinter == 'undefined') {
                    tenantPrinter = undefined
                }
                const el = document.getElementById("printQueue");

                const options = {
                    type: "dataURL",
                    scale: 1
                };
                this.output = await this.$html2canvas(el, options);
                const appointment = this.modePrint != 'unit' ? `Appoinment: ${vx.detailPrint.schedule}` : ''
                var data = [
                    "\x1B" + "\x40", //init
                    "\x1B" + "\x61" + "\x31", // center align
                    "\x1B" + "\x45" + "\x0D", // bold on
                    "\x1B" + "\x21" + "\x30", // em mode on
                    `${
                        tenantPrinter !== undefined
                            ? tenantPrinter
                            : "RS MANDAYA"
                    }`,
                    "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // em mode off
                    "\x1B" + "\x45" + "\x0A", // bold off
                    "\x0A" + "\x0A",
                    `${vx.detailPrint.displayName1}`,
                    "\x0A",
                    `${vx.detailPrint.displayName2 ? vx.detailPrint.displayName2 : ''}`,
                    "\x0A",
                    `${appointment}`,
                    "\x0A",
                    {
                        type: "raw",
                        format: "image",
                        flavor: "base64",
                        data: this.output,
                        options: { language: "ESCPOS" }
                    },
                    `${vx.detailPrint.date} `,
                    "\x0A",
                    "Mohon menunggu nomor Anda dipanggil",
                    "\x0A" + "\x0A", //new lines
                    // <!-- BEGIN QR DATA -->
                    "\x1D" + "\x28" + "\x6B" + "\x04" + "\x00" + "\x31" + "\x41" + "\x32" + "\x00", // <Function 165> select the model (model 2 is widely supported)
                    "\x1D" + "\x28" + "\x6B" + "\x03" + "\x00" + "\x31" + "\x43" +
                    dots, // <Function 167> set the size of the module
                    "\x1D" + "\x28" + "\x6B" + "\x03" + "\x00" + "\x31" + "\x45" + "\x30", // <Function 169> select level of error correction (48,49,50,51) printer-dependent
                    "\x1D" + "\x28" + "\x6B" +
                        size1 +
                        size0 + "\x31" + "\x50" + "\x30" +
                        qr, // <Function 080> send your data (testing 123) to the image storage area in the printer
                    "\x1D" + "\x28" + "\x6B" + "\x03" + "\x00" + "\x31" + "\x51" + "\x30", // <Function 081> print the symbol data in the symbol storage area
                    "\x1D" + "\x28" + "\x6B" + "\x03" + "\x00" + "\x31" + "\x52" + "\x30", // <Function 082> Transmit the size information of the symbol data in the symbol storage area
                    // <!-- END QR DATA -->
                    "\x0A",
                    "\x1B" + "\x4D" + "\x31", // small text
                    "Scan QR ini dengan Aplikasi Care Dokter",
                    "\x0A",
                    "Download di Google Play Store & Apple App Store",
                    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A", // more line feeds and text to see if we messed up the QR Code syntax
                    "\x1D" + "\x56" + "\x01" //CUT
                ];
                try {
                    await qz.print(config, data).catch(function(e) {
                        console.error(e);
                    });
                } catch (err) {
                    console.log(err);
                }

                if (localStorage.copiesNumber) {
                    var data = [
                        {
                            type: "pixel",
                            format: "html",
                            flavor: "plain", // or 'plain' if the data is raw HTML
                            data: `${
                                document.getElementById("small-ticket")
                                    .outerHTML
                            }`
                        }
                    ];
                    let counter = 0;
                    let maxNumber = localStorage.copiesNumber < 5 ? localStorage.copiesNumber : 5
                    while (counter != maxNumber) {
                        await qz.print(config, data).catch(function(e) {
                            console.error(e);
                        });
                        counter++;
                    }
                }
                vx.setLoading(false);
                // vx.payloadPrint.printSelected = null;
            }
        }
    },
    props: {
        detailPrint: { type: Object },
        modePrint:{
            type: String,
            default: 'all'
        }
    },
    watch: {
        'detailPrint.content': function(nuVal, olVal) {
            if (nuVal) {
                let con = JSON.parse(this.detailPrint.content)
                con.tenantId = `${this.selectedTent}`
                this.detailPrint.content = JSON.stringify(con)
            }
        }
    },
    components: {
        VueQrcode
    },
    mounted() {
        this.tenantPrinter = localStorage.getItem("tenantPrinter");
        this.selectedTent = window.tenantActive
    }
};
</script>

<style></style>
